import React from 'react';

function PropertyDetails({ match }) {
  // Fetch property details using the id from match.params.id
  // For now, this is a static page
  return (
      <div>
        <div id="MBBv3_SearchDetails"></div>
      </div>
  );
}

export default PropertyDetails;
